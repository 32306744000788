/* bootstrap overwrite */
.main-area .link-list-component .list-group {
  border-top: 1px solid #c9d1e3;
}
.main-area .link-list-component .list-group .list-group-item::before {
  /* mask: $icon-arrow-down; */
  background-color: var(--accent-color);
}
.main-area .link-list-component .list-group .list-group-item:hover::before {
  background-color: var(--accent-hover-color);
}