@import 'webresources-sources/scss/imports';

.main-area {
  .link-list-component {
    .list-group {
      border-top: 1px solid $color-gray-500;

      .list-group-item {
        &::before {
          /* mask: $icon-arrow-down; */
          background-color: var(--accent-color);
        }

        &:hover {
          &::before {
            background-color: var(--accent-hover-color);
          }
        }
      }
    }
  }
}
